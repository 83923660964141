import React, { FunctionComponent, useEffect, useState } from "react";
import {
  ActivityIndicator,
  FlatList,
  View,
  Text,
  StyleSheet,
} from "react-native";
import { useMutation, useQuery, useQueryClient } from "react-query";
import styled from "styled-components/native";

import { Job, JobStatus } from "../../../../../dtos/job";
import { User } from "../../../../../dtos/user";
import { ApiError } from "../../../../../services/api/api";
import { QueryKey } from "../../../../../services/api/query";
import { getJob } from "../../../../../services/jobService";
import {
  createBasiqBankJob,
  getUser,
  updateBasiqBankJob,
} from "../../../../../services/userService";
import Button, { AppButtonProps } from "../../../../atoms/Button";
import { HCenterStack } from "../../../../layout/HStack";
import { ScreenContainer } from "../../../../layout/ScreenContainer";
import { Footer } from "../../../../molecules/Footer";
import { OnboardingMessage } from "../components/OnboardingMessage";
import { appConfig } from "../../../../../config/config";
import { MediumText } from "../../../../atoms/MediumText";
import { Spinner } from "../../../../organisms/Spinner";
import ClientLogo from "../../../../atoms/ClientLogo";
import { authStyles } from "../../../Auth/shared";
import { IJobFeedback } from "../../../../../constants/Common";
import { generateNewConsentLink } from "../../../../../services/bankService";
import { JobFeedback } from "../../../../organisms/JobFeedback";

const StyledFooterContainer = styled.View`
  margin-top: 50px;
`;

const ActivateConsentSteps: IJobFeedback = {
  steps: [
    {
      title: 'Creating a new consent link',
      context: {
        status: 'pending',
      }
    },
  ],
  description: "We are creating a new consent link.",
}

type BasiqJobStatusFailureProps = {};
export const BasiqJobStatusFailure: FunctionComponent<
  BasiqJobStatusFailureProps
> = () => {
  const queryClient = useQueryClient();
  const [reconsentRunning, setReconsentRunningState] = useState<boolean>(false);
  const [loaderSteps, setLoaderSteps]: any = useState(ActivateConsentSteps);

  const { mutate: generateNewConsent, data: reconsentJobData } = useMutation(
    updateBasiqBankJob,
    {
      mutationKey: [QueryKey.CreateReconsentBasiqBankJob],
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.CreateBasiqBankJob);
      },
    }
  );

  const { data: createdBankJobData, refetch: createBasiqJob } = useQuery<
    Job | null,
    ApiError
  >({
    queryKey: [QueryKey.CreateBasiqBankJob],
    queryFn: () => createBasiqBankJob(),
    enabled: false,
    refetchOnWindowFocus: false,
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKey.CreateReconsentBasiqBankJob);
    },
  });

  const { data: jobStatusData } = useQuery<Job | null, ApiError>({
    queryKey: [QueryKey.Jobs],
    queryFn: () =>
      getJob(
        reconsentJobData ? reconsentJobData?._id : createdBankJobData?._id
      ),
    enabled: !!reconsentJobData || !!createdBankJobData,
    refetchInterval: (data) => {
      return data && data?.status === JobStatus.Pending ? 1000 : false;
    },
  });

  const { data: user, isLoading: isUserLoading } = useQuery<
    User | null,
    ApiError
  >({
    queryKey: [QueryKey.User],
    queryFn: () => getUser(),
    enabled: !!jobStatusData && jobStatusData.status === JobStatus.Success,
  });

  const { mutate: generateConsent, data: ConsentLinkStatus } = useMutation(generateNewConsentLink, {
    onError: (error: any) => { },
    onSuccess: (data: any) => {
      let temp: any = { ...ActivateConsentSteps };
      temp.steps[0].context.status = 'success';
      setLoaderSteps(temp);
    }
  });


  useEffect(() => {
    generateConsent();
  }, [])

  // useEffect(() => {
  //   if (jobStatusData && jobStatusData?.status !== JobStatus.Pending) {
  //     setReconsentRunningState(false);
  //   }
  // }, [jobStatusData, user]);

  if (isUserLoading) {
    return <Spinner />;
  }

  return user ? (
    <ScreenContainer center>
      <HCenterStack style={authStyles.imageContainer}>
        <View style={{ width: "70%" }}>
          <ClientLogo />
        </View>
      </HCenterStack>
      <OnboardingMessage user={user} />
      {((user && !user.consent) || reconsentRunning) && (
        <HCenterStack>
          <ActivityIndicator size="large" color={appConfig.primaryColour} />
        </HCenterStack>
      )}

      <View style={rnStyles.listContainerSeparator} />
      <JobFeedback
        steps={loaderSteps?.steps}
        description={''}
      />

      <Button
        label="Reconsent"
        colour={reconsentRunning ? "grey" : appConfig.primaryColour}
        onPress={() => {
          if (ConsentLinkStatus && ConsentLinkStatus.consent) {
            window.open(ConsentLinkStatus.consent, "_self");
          }
        }}
        disabled={loaderSteps?.steps && loaderSteps?.steps[0].context.status === 'pending'}
      />

      <View style={rnStyles.listContainerSeparator} />
      <ButtonListByUser
        user={user}
        createBasiqJob={createBasiqJob}
        generateNewConsent={generateNewConsent}
        isSpinnerRunning={reconsentRunning}
        setSpinner={setReconsentRunningState}
      />
      <StyledFooterContainer>
        <Footer />
      </StyledFooterContainer>
    </ScreenContainer>
  ) : (
    <></>
  );
};

enum ButtonLabels {
  createJob = "Create Job",
  failedConsent = "Update Failed Consent",
  expiredConsent = "Update Expired Consent",
}

type ButtonMeta = {
  isDescriptionLink?: boolean;
};
type ButtonListByUserProps = {
  user?: User | null;
  isSpinnerRunning?: boolean;
  createBasiqJob: () => void;
  generateNewConsent: (v: object) => void;
  setSpinner: React.Dispatch<React.SetStateAction<boolean>>;
};

const ButtonListByUser: FunctionComponent<ButtonListByUserProps> = ({
  user,
  createBasiqJob,
  generateNewConsent,
  isSpinnerRunning,
  setSpinner,
}) => {
  if (user) {
    let buttonList: (AppButtonProps & ButtonMeta)[] = [];
    const { basiqJobStatus } = user;

    const jobStatusList = basiqJobStatus ?? [];

    if (jobStatusList.length === 0) {
      // Then create a basiq job
      buttonList = [
        ...buttonList,
        {
          label: ButtonLabels.createJob,
          isDescriptionLink: true,
          onPress: () => {
            if (!isSpinnerRunning) {
              setSpinner(true);
              createBasiqJob();
            }
          },
        },
        {
          label: ButtonLabels.expiredConsent,
          colour: "grey",
          isDescriptionLink: true,
          onPress: () => {
            if (!isSpinnerRunning) {
              setSpinner(true);
              generateNewConsent({ consentRequestType: "consent_expired" });
            }
          },
        },
      ];
    }

    const failedJobs: IBasiqJobStatus[] = jobStatusList.filter(
      ({ status }) => status === "failed"
    );

    const failedJobActionMapList: Array<{
      title: string;
      isUserActionRequired: boolean;
    }> = failedJobs.map(({ title, isUserActionRequired }) => ({
      title,
      isUserActionRequired,
    }));

    for (let i = 0; i < failedJobActionMapList.length; i++) {
      buttonList = [
        ...buttonList,
        {
          label: ButtonLabels.failedConsent,
          isDescriptionLink: true,
          onPress: () => {
            if (!isSpinnerRunning) {
              setSpinner(true);
              generateNewConsent({ consentRequestType: "consent_failed" });
            }
          },
        },
        {
          label: ButtonLabels.expiredConsent,
          isDescriptionLink: true,
          colour: "grey",
          onPress: () => {
            if (!isSpinnerRunning) {
              setSpinner(true);
              generateNewConsent({ consentRequestType: "consent_expired" });
            }
          },
        },
      ];
    }

    const updatedList = buttonList.filter(
      (btn, index) =>
        buttonList.findIndex((fBtn) => btn.label === fBtn.label) === index
    );

    return (
      <FlatList
        data={updatedList}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={rnStyles.listContentContainer}
        ItemSeparatorComponent={() => <View style={rnStyles.buttonSeparator} />}
        renderItem={({ item, index }) => {
          const { ...props } = item;
          // return <Button {...props} />;
          return props.label === ButtonLabels.createJob ? (
            <Button {...props} />
          ) : (<></>);
        }}
        keyExtractor={(item, index) => `${item.label}-${index}`}
      />
    );
  }
  return <></>;
};

const rnStyles = StyleSheet.create({
  listContentContainer: {
    paddingTop: 5,
  },
  linkButton: {
    fontWeight: "700",
    textDecorationLine: "underline",
  },
  buttonSeparator: {
    // height: 5,
  },
  reconsentButton: {
    height: 5,
  },
  listContainerSeparator: {
    height: 15,
  },
});
