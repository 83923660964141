import React, { ReactNode, useContext, useState, useEffect } from "react";
import { useQuery } from "react-query";
import { User, UserDataUpdatedBy } from "../dtos/user";
import { useError } from "../context/errorContext";
import { ApiError } from "../services/api/api";
import { QueryKey } from "../services/api/query";
import { getUser } from "../services/userService";

type UserContext = {
  user?: User | null;
  error?: ApiError | null;
  isUserLoading: boolean;
};

const UserContext = React.createContext<UserContext | undefined>(undefined);

const UserProvider = ({
  children,
}: { children: ReactNode }): React.ReactElement => {
  const { setCriticalError } = useError();
  const useUserQuery = useQuery<User | null, ApiError>(
    [QueryKey.User],
    () => getUser(),
    {
      onError: (e) => setCriticalError(e),
      onSuccess: (data) => {
      }
    }
  );



  const userContextValue: UserContext = {
    user: useUserQuery.data,
    error: useUserQuery.error,
    isUserLoading: useUserQuery.isFetching,
  };



  return (
    <UserContext.Provider value={userContextValue}>
      {children}
    </UserContext.Provider>
  );
};

const useUser = (): UserContext => {
  const userContext = useContext(UserContext);
  if (!userContext) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return userContext;
};

export { UserProvider, useUser };
