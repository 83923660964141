import React, { FunctionComponent, useState, useEffect } from "react";
import { ScrollView } from "react-native-gesture-handler";
import { t } from "react-native-tailwindcss";
import { Dimensions, View, ActivityIndicator } from "react-native";
import { DrawerScreenProps } from "@react-navigation/drawer";

import { ScreenContainer } from "../../../layout/ScreenContainer";
import { LinkedSavingsAccount } from "./LinkedSavingsAccount";
import { MediumText } from "../../../atoms/MediumText";
import ClientLogo from "../../../atoms/ClientLogo";
import { HCenterStack } from "../../../layout/HStack";
import Card from "../../../atoms/Card";
import { appConfig } from "../../../../config/config";
import { WEB_HEADER_HEIGHT } from "../../../../web-helper";
import { Dropdown } from "../../../atoms/Dropdown";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useToast } from "react-native-toast-notifications";
import { QueryKey } from "../../../../services/api/query";
import { useUser } from '../../../../context/userContext';
import { useClubList } from '../../../../context/clubContext';
import { UpdateClubForUser } from "../../../../services/clubService";

type LinkedSavingsAccountScreenProps = {} & DrawerScreenProps<
  AuthenticatedRootStackParamList,
  "LinkedSavingsAccount"
>;

type errorSuccessMsg = {
  isError: boolean,
  isDisplay: boolean,
  boxColour: string,
  message: string,
}

export const LinkedSavingsAccountScreen: FunctionComponent<
  LinkedSavingsAccountScreenProps
> = (props) => {
  const { height } = Dimensions.get("window");
  const toast = useToast();
  const queryClient = useQueryClient();
  const { user, isUserLoading } = useUser();
  const { clubList, isClubsLoading } = useClubList();
  const [selectedClub, setSelectedClub] = useState<string | null | undefined>(null);
  const [errorAndSuccessMsg, setErrorAndSuccessMsg] = useState<errorSuccessMsg | null>(null);

  useEffect(() => {
    setSelectedClub(user?.supportingClubId);
  }, [user, clubList]);

  useEffect(() => {
    if (errorAndSuccessMsg && errorAndSuccessMsg.isDisplay) {
      setTimeout(() => {
        setErrorAndSuccessMsg(null);
      }, 2500);
    }
  }, [errorAndSuccessMsg])

  const { mutate, isLoading: loadingClubUpdate } = useMutation(UpdateClubForUser, {
    onSuccess: (data) => {
      setErrorAndSuccessMsg({
        isError: false,
        isDisplay: true,
        boxColour: '#00c851',
        message: 'Club selected successfully'
      })
      queryClient.invalidateQueries(QueryKey.User)
    },
    onError: (error: any) => {
      if (error) {
        setErrorAndSuccessMsg({
          isError: true,
          isDisplay: true,
          boxColour: '#ff4444',
          message: 'Club selected successfully'
        })
      }
    },
  });

  const onClubSelect = (value: string) => {
    setErrorAndSuccessMsg(null);
    setSelectedClub(value);
    mutate({ supportingClubId: value });
  }

  const displayErrorAndSuccessMsg = () => {
    if (errorAndSuccessMsg && errorAndSuccessMsg.isDisplay) {
      return (
        <Card style={{
          borderRadius: 10,
          backgroundColor: errorAndSuccessMsg?.boxColour,
          padding: 5,
          width: 'fit-content',
          alignSelf: 'center'
        }}>
          <MediumText center style={[t.textWhite, { padding: 3 }]}>
            {errorAndSuccessMsg?.message}
          </MediumText>
        </Card>
      )
    }
  }

  return (
    <ScrollView>
      {/* appConfig.dashboardBackground */}
      <ScreenContainer
        backgroundColor={appConfig.dashboardBackground}
        style={{ height: height - WEB_HEADER_HEIGHT }}
      >
        <Card style={styles.card}>
          <HCenterStack>
            <View style={{ height: 70, width: "100%", paddingBottom: 20 }}>
              <ClientLogo />
            </View>
          </HCenterStack>
          <MediumText center style={[t.fontBold, { padding: 3, paddingVertical: 25 }]}>
            Link a savings account for your roundups to be deposited into monthly
          </MediumText>
          <LinkedSavingsAccount {...props} />
        </Card>
        {displayErrorAndSuccessMsg()}
      </ScreenContainer>
    </ScrollView>
  );
};
const styles = {
  card: [t.flex, t.flexCol, t.p5, t.mB6, t.bgWhite, t.shadowXl],
  itemContainer: [t.flex, t.flexCol, t.flex1, { height: "70%" }],
};
