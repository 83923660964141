import React from "react";
import { t } from "react-native-tailwindcss";
import { Text } from "react-native";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { getAuth, signOut } from "firebase/auth";
import { useQueryClient } from "react-query";
import AsyncStorage from "@react-native-async-storage/async-storage";

import { useAuth } from "../../context/authContext";
import { firebaseApp } from "../../config/firebase";
import { QueryKey } from "../../services/api/query";
import { useError } from "../../context/errorContext";
import { DASHBOARD_GRANTED_TIME } from "../../constants/Common";
import { retrieveLoginData, FormData, saveLoginData } from '../../components/screens/Auth/Login/LoginLocalStorageHandler';
import { userLoginType } from '../../dtos/user';
import jwt_decode from "jwt-decode";
import { appConfig } from "../../config/config";


interface IFooterProps {
  hideLogOut?: boolean;
}

export const Footer = ({ hideLogOut = false }: IFooterProps): React.ReactElement => {
  const { setCriticalError } = useError();
  const navigation = useNavigation<NavigationProp<any>>();
  const queryClient = useQueryClient();
  const { authenticated } = useAuth();

  const logoutPressed = () => {
    // Redirection for SSO
    const loginData: FormData = retrieveLoginData();

    setCriticalError(null);
    const auth = getAuth(firebaseApp);
    signOut(auth).then(async () => {
      queryClient.setQueryData(QueryKey.User, () => null);
      queryClient.setQueryData(QueryKey.Accounts, () => null);
      // TODO: Must remove Dashboard bypass key
      await AsyncStorage.removeItem(DASHBOARD_GRANTED_TIME);
      // Used to reload

      if (loginData &&
        (
          loginData.loginType &&
          loginData.loginType === userLoginType.sso
        )) {
        getRedirectUrlFromToken(loginData);
      }
      else {
        window.location.reload();
      }
    });
  }

  const getRedirectUrlFromToken = (loginData: FormData) => {
    // The logout redirection logic can be introduced in the future
    // So that this method has been created seperately to handle that
    const loginDataForSave = {
      email: "",
      password: "",
      isRememberMe: false,
      loginType: userLoginType.emailPass,
      firebaseToken: "",
      isLoggedIn: false
    }
    saveLoginData(loginDataForSave)

    let isApp = localStorage.getItem("isApp");
    if (isApp === 'true') {
      localStorage.setItem('isApp', 'redirect');
      window.location.reload();
    } else {
      console.log('appConfig.redirectUrl', appConfig.redirectUrl);

      if (appConfig.redirectUrl) {
        window.location.replace(appConfig.redirectUrl.toString());
      } else {
        window.location.reload();
      }
    }
  }

  return (
    <Text style={[styles.footerText]}>
      <Text
        style={styles.coloredLink}
        onPress={() => navigation.navigate("Help")}
      >
        Help{" "}
      </Text>
      <Text
        style={styles.coloredLink}
        onPress={() => navigation.navigate("Terms")}
      >
        Terms{" "}
      </Text>
      <Text
        style={styles.coloredLink}
        onPress={() => navigation.navigate("PrivacyPolicy")}
      >
        Privacy{" "}
      </Text>
      {authenticated && (!hideLogOut) && (
        <>
          <Text
            style={styles.coloredLink}
            onPress={logoutPressed}
          >
            Logout
          </Text>
        </>
      )}
    </Text>
  );
};

const styles = {
  footerText: [t.textCenter, t.mT2, t.mB10],
  coloredLink: [t.textBlue400, t.mR4],
};

interface jwtToken {
  redirectUrl: string;
}