import React, { useState, useCallback, useRef, useEffect } from "react";
import { View, Text } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { appConfig } from "../../../../config/config";

import { ScreenContainer } from "../../../layout/ScreenContainer";
import { MonthlyPayments } from "./MonthlyPayments";
import { RoundupAmounts } from "./RoundupAmounts";
import { TotalAmounts } from "./TotalAmounts";
import { LinkedSavingsAccount } from "./LinkedSavingsAccount";
import { useMutation, useQuery } from "react-query";
import { LinkAccount } from "../../../../dtos/job";
import { QueryKey, queryClient } from "../../../../services/api/query";
import { getLinkedSavingsAccount, setRoundupStatus } from "../../../../services/userService";
import { useToast } from "react-native-toast-notifications";
import { DonateNowComponenet } from "../DonateWidget/DonateNowComponent";
import Snackbar from "../../../atoms/Snakbar";
import { t } from "react-native-tailwindcss";
import { ConfirmationPopupRef } from "../../../layout/ConfirmationPopup";
import RoundUpDisablePopUp from "../../../organisms/RoundUpDisablePopUp";
import ConsentUpdate from "../../../organisms/ConsentUpdate";
import { useUser } from "../../../../context/userContext";
import { FirebaseError } from "firebase/app";
import { CustomErrors } from "../../Auth/Registration/RegistrationScreen";




export const DashboardScreen = (): React.ReactElement => {
  const {
    user,
    isUserLoading
  } = useUser();
  const toast = useToast();
  const [isConsentDeactivated, setIsConsentDeactivated] = useState<boolean>(false);
  const confirmationConsentDialogRef = useRef<ConfirmationPopupRef>(null);
  const confirmationRoundupRef = useRef<ConfirmationPopupRef>(null);
  const [customErrors, setCustomErrors] = useState<CustomErrors>({});


  const { data: linkedData, isFetching } = useQuery<LinkAccount>(
    [QueryKey.LinkedSavingAccount],
    () => getLinkedSavingsAccount(),
    {
      onError: (error: any) => {
        if (error) {
          toast.show(error.message, {
            type: "danger",
          });
        }
      },
    }
  );

  const mutation = useMutation(setRoundupStatus, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(QueryKey.User);
      setCustomErrors({});
    },
  });


  const onCloseConsentBar = () => {
    setIsConsentDeactivated(false)
  }

  const showConfirmationDialog = () => {
    confirmationConsentDialogRef.current?.show();
  }

  const showRoundUpConfirmation = () => {
    confirmationRoundupRef.current?.show();
  }

  const setRoundUpStatus = async (status: boolean) => {
    //showErrorMgs();
    mutation.mutate(status);
  };


  useEffect(() => {
    setIsConsentDeactivated(user?.isDeactivated || false)
  }, [user]);

  return (
    <ScrollView style={{ backgroundColor: appConfig.dashboardBackground }}>
      {isConsentDeactivated ?
        <Snackbar
          message={
            <Text>
              You have un-linked your financial institution. Click <Text onPress={showConfirmationDialog} style={styles.link}>here</Text> to link again.
            </Text>
          }
          isClosable={true}
          type="warning"
          onClose={onCloseConsentBar}
        />
        : <></>}
      <View style={{
        backgroundColor: appConfig.primaryColour,
        height: '35%',
        width: '100%',
        position: 'absolute',
        top: isConsentDeactivated ? '74px' : '0px',
      }}>
      </View>
      <ScreenContainer style={{ backgroundColor: 'transparent' }} padding={25}>
        <TotalAmounts />
        {user && (
          <RoundupAmounts
            showConsentConfirmation={showConfirmationDialog}
            showRoundUpConfirmation={showRoundUpConfirmation}
            isLoadingFromPopUp={mutation.isLoading}
            error={customErrors}
            user={user}
            isUserLoading={isUserLoading}
          />
        )}
        <LinkedSavingsAccount linkedData={linkedData} isFetching={isFetching} />
        <DonateNowComponenet />
        <MonthlyPayments />
      </ScreenContainer>
      <ConsentUpdate
        ref={confirmationConsentDialogRef}
      />
      <RoundUpDisablePopUp
        ref={confirmationRoundupRef} handleOnConfirm={function (): void {
          setRoundUpStatus(true);
          confirmationRoundupRef.current?.hide();
        }} />

    </ScrollView>
  );
};

const styles = {
  link: [t.underline],
};