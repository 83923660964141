import { ContributionLimit } from "./contributionLimit";
import { appConfig } from "../config/config";

export type User = {
  errors: any;
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  userId: string;
  state: string;
  mobile: string;
  country: string;
  tenant: string;
  _deleted: boolean;
  _createdDate: string;

  // These indicate the user has set a funding account. They change when the funding account changes
  paymentAuthoriserId: string;
  paymentContactId: string;

  // This indicates the user has connected their bank account
  externalUserId?: string;

  _updatedDate: string;
  institution?: string;
  fixedupAmountCents?: number;
  roundupAmountCents?: number;
  roundupAmountCentsOld?: number;
  debit?: {
    debitSchedule?: "weekly" | "monthly";
    debitAmountCents?: number;
  };
  onboardingComplete?: boolean;
  contributionLimit?: ContributionLimit;
  consent: string;
  isConsent: boolean;
  isDeactivated: boolean;
  basiqJobStatus?: Array<IBasiqJobStatus>;
  savingsAccountEnabled?: boolean;
  weeklyMaximumLimit?: number;
  updatedBy?: UserDataUpdatedBy;
  supportingClubId?: string;
  isWeeklyMaximumLimitReached?: boolean;
  isUserPausedRoundUp?: boolean;
};

export enum UserDataUpdatedBy {
  user = "user",
  system = "system",
}

export const userCredsRememberObj = {
  saveUserCredsSecretPass: appConfig.saveUserCredsSecretPass,
  saveUserItemKey: "loginData"
}

export const loginType = "LOGIN_TYPE"

export enum userLoginType {
  sso = "SSO",
  emailPass = "EMAIL_PASS",
}
