import React, { useEffect, useState } from 'react';
import { useQueryClient, useMutation } from 'react-query';
import { View, ActivityIndicator, Text } from 'react-native';
import { t } from 'react-native-tailwindcss';
import { ScrollView } from 'react-native-gesture-handler';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { useToast } from 'react-native-toast-notifications';
import styled from 'styled-components/native';
import { FirebaseError } from "firebase/app";
import { authStyles } from "../../Auth/shared";
import { AppScreensParamList } from '../../../../navigation/app-screens';
import { setRoundupAmount, setRoundupStatus } from '../../../../services/userService';
import { ApiError, getErrorMessage } from '../../../../services/api/api';
import { QueryKey } from '../../../../services/api/query';
import { useAccounts } from '../../../../context/accountsContext';
import { useUser } from '../../../../context/userContext';
import Box from '../../../atoms/Box';
import Button from '../../../atoms/RoundedButtons';
import AccordionCard from '../../../organisms/AccordionCard';
import { HCenterStack, HStack } from '../../../layout/HStack';
import { LineTitle } from '../../../molecules/LineTitle';
import { MediumText } from '../../../atoms/MediumText';
import { WeeklyMaximumLimit } from '../WeeklyMaximumLimit/WeeklyMaxLimitComponent';
import { User, UserDataUpdatedBy } from "../../../../dtos/user";
import { CustomErrors } from "../../Auth/Registration/RegistrationScreen"

const StyledRoundupAmountSelectorContainer = styled(HCenterStack)`
  width: 100%;
`;

export const RoundupAmounts = ({
  user,
  isUserLoading,
  showConsentConfirmation = () => { },
  showRoundUpConfirmation = () => { },
  isLoadingFromPopUp,
  error
}: {
  user: User;
  isUserLoading: boolean;
  showConsentConfirmation: any;
  showRoundUpConfirmation: any;
  isLoadingFromPopUp: boolean;
  error?: CustomErrors;

}): React.ReactElement => {
  const toast = useToast();
  // const { user,
  //   isUserLoading
  // } = useUser();
  const { accounts } = useAccounts();
  const navigation = useNavigation<NavigationProp<AppScreensParamList>>();
  const queryClient = useQueryClient();
  const [roundupAmountCents, setRoundupAmountCents] = useState<number>(user?.roundupAmountCents ?? 0);
  const [isRoundUpPaused, setIsRoundUpPaused] = useState<boolean>(user?.isUserPausedRoundUp ?? false);
  const [isWeeklyMaximumReached, setIsWeeklyMaximumReached] = useState<boolean>(user?.isWeeklyMaximumLimitReached ?? false);
  const [customErrors, setCustomErrors] = useState<CustomErrors>({});
  const [isConsentDeactivated, setIsConsentDeactivated] = useState<boolean>(false);


  useEffect(() => {
    console.log('roundUpAmt -- ', roundupAmountCents);
    setRoundupAmountCents(user?.roundupAmountCents ?? 0);
    setIsRoundUpPaused(user?.isUserPausedRoundUp ?? false);
    setIsWeeklyMaximumReached(user?.isWeeklyMaximumLimitReached ?? false);

    setIsConsentDeactivated(user?.isDeactivated || false);
  }, [user]);

  const showErrorMgs = (error?: (ApiError & Error & FirebaseError)) => {
    if (error) {
      setCustomErrors({
        ...customErrors,
        general: getErrorMessage(error),
      });
    } else {
      setCustomErrors({});
    }
  }

  const mutation = useMutation(setRoundupAmount, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(QueryKey.User);
    },
    onError: (error: ApiError & Error & FirebaseError) => {
      showErrorMgs(error);
    },
  });

  const roundUpStatus = useMutation(setRoundupStatus, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(QueryKey.User);
    },
    onError: (error: ApiError & Error & FirebaseError) => {
      showErrorMgs(error);
    },
  });


  const setRoundups = async (amount: number) => {
    showErrorMgs();
    mutation.mutate(amount);
  };

  const setRoundUpStatus = async (status: boolean) => {
    showErrorMgs();
    roundUpStatus.mutate(status);
  };

  const toggleSwitch = async () => {
    if (isConsentDeactivated) {
      showConsentConfirmation();
      return;
    }
    if (!isRoundUpPaused) {
      // Turn off the round up
      showRoundUpConfirmation();
    } else {
      setIsRoundUpPaused(false);
      await setRoundUpStatus(false);
    }
  };

  const displayWeeklyMaximumMsg = () => {
    if (isWeeklyMaximumReached && !isUserLoading) {
      return (
        <MediumText style={{ paddingBottom: 20, color: '#ce2929', textAlign: 'center' }}>
          You have reached your weekly maximum limit
        </MediumText>
      );
    } else {
      return null;
    }
  }

  const roundUpAmtList: { title: string, value: number }[] = [
    {
      title: '$1',
      value: 100,
    },
    {
      title: '$2',
      value: 200,
    },
    {
      title: '$5',
      value: 500,
    }
  ]

  return (
    <AccordionCard
      checked={!!user?.isDeactivated ? false : !isRoundUpPaused}
      toggleSwitch={toggleSwitch}
      style={[t.mB6, t.bgWhite, t.shadowXl]}
      title={`Round-Ups ${roundupAmountCents ? 'on' : 'off'}`}
      isDisabled={mutation.isLoading || roundUpStatus.isLoading || isLoadingFromPopUp || isUserLoading}
    >
      <LineTitle title="Amount" />
      {mutation.isLoading || isLoadingFromPopUp || roundUpStatus.isLoading || isUserLoading ?
        <ActivityIndicator />
        :
        <View>
          {displayWeeklyMaximumMsg()}
          <StyledRoundupAmountSelectorContainer>
            {roundUpAmtList.map((b, i) => (
              <Box
                title={b.title}
                checked={b.value === roundupAmountCents}
                onPress={() => {
                  setRoundupAmountCents(b.value);
                  setRoundups(b.value);
                }}
                key={i.toString()}
              />
            ))}
          </StyledRoundupAmountSelectorContainer>
        </View>
      }
      <WeeklyMaximumLimit setCustomErrors={showErrorMgs} />
      <View style={t.mT4}>
        <LineTitle title="Linked Roundup Accounts" />
      </View>
      <ScrollView contentContainerStyle={{ maxHeight: 100 }}>
        {accounts
          ?.filter((a) => a.activeRoundUpsEnabled)
          .map((account, i) => (
            <MediumText key={i.toString()}>
              {account.name}: {account.accountNumber}
            </MediumText>
          ))}
      </ScrollView>
      <HStack style={[t.pT4, { justifyContent: 'center' }]}>
        <View style={{ width: '70%' }}>
          <Button
            label="Manage accounts"
            onPress={() => navigation.navigate('RoundupAccounts')}
          />
        </View>
      </HStack>
      {customErrors.general && (
        <Text style={[authStyles.error, t.mT2]}>
          {customErrors.general}
        </Text>
      )}
    </AccordionCard>
  );
};
